<template>
  <c-box class="end-content" :w="['100%', '794px']" align="center">
    <c-box
      v-if="item.type === 'daily_coaching'"
      border-y="1px solid #f2f2f2"
      mt="42px"
      py="20px"
      mb="35px"
      align="center"
    >
      <c-text
        align="center"
        font-size="18px"
        font-weight="bold"
        lineHeight="27px"
        color="#555555"
      >
        Konten Hari Ke-{{ item.showOnDay }}
      </c-text>
    </c-box>
    <c-text
      align="justify"
      fontSize="18px"
      lineHeight="27px"
      color="#555555"
      mb="63px"
      v-html="item.description"
    />
    <c-box
      v-if="images && images.length > 0"
      align="center"
      :mt="item.type !== 'daily_coaching' ? '63px' : ''"
    >
      <Carousel
        :per-page="1"
        :navigation-enabled="true"
        pagination-active-color="#008c81"
        v-chakra
        :w="['100%', '650px']"
        :h="['auto', null]"
      >
        <Slide
          v-chakra
          :w="['100%', '600px']"
          v-for="(media, idx) in images"
          :key="idx"
        >
          <c-box :w="['100%', '590px']">
            <c-image :src="media.url" :alt="media.caption" mb="34px" />
            <c-text
              align="justify"
              font-size="18px"
              lineHeight="27px"
              color="#333333"
            >
              {{ media.caption }}
            </c-text>
          </c-box>
        </Slide>
      </Carousel>
    </c-box>
    <c-flex
      v-for="(media, index) in videos"
      :key="index"
      flex-dir="column"
      align-items="center"
      :w="['100%', '600px']"
      mt="52px"
    >
      <c-box
        :w="['100%', '590px']"
        :h="['auto', '370px']"
        border-radius="10px"
        overflow="hidden"
        bg="black"
        mb="34px"
      >
        <video
          v-chakra
          w="100%"
          h="100%"
          :src="media.url"
          controls
          autoplay="false"
          muted="muted"
        />
      </c-box>
      <c-text
        align="justify"
        font-size="18px"
        lineHeight="27px"
        color="#333333"
        w="100%"
      >
        {{ media.caption }}
      </c-text>
    </c-flex>
    <c-flex
      v-for="(media, index) in youtube"
      :key="'yt' + index"
      align="center"
      justify="center"
      mt="52px"
    >
      <youtube
        :video-id="getYoutubeId(media.url)"
      ></youtube>
    </c-flex>
    <c-flex flex-dir="column" grid-gap="20px" :w="['100%', '600px']" mt="98px">
      <c-flex
        v-for="(item, idxFile) in files"
        :key="'file_' + idxFile"
        w="100%"
        grid-gap="20px"
      >
        <c-flex
          align-items="center"
          justify-content="center"
          h="100px"
          w="100px"
          bg="#F4CC4619"
          border-radius="12px"
        >
          <c-image
            w="50px"
            h="50px"
            :src="require('@/assets/icon-folder.svg')"
            alt="icon-folder"
          />
        </c-flex>
        <c-flex
          justify-content="center"
          align-items="baseline"
          flex-dir="column"
          flex="1"
        >
          <c-text
            font-size="24px"
            line-height="150%"
            text-align="justify"
            color="#333333"
          >
            {{ item.fileName }}
          </c-text>
          <c-text font-size="18px" line-height="150%" color="#888888">
            {{ formatBytes(item.fileSize) }}
          </c-text>
        </c-flex>
        <c-flex align-items="center" justify-content="center" w="50px">
          <c-button
            @click.prevent="downloadFile(idxFile, item)"
            type="button"
            variant="ghost"
            :is-loading="downloading === idxFile"
            variant-color="green"
            m="0"
            p="0"
          >
            <c-image
              :src="require('@/assets/icon-downloads.svg')"
              alt="icon-download"
            />
          </c-button>
        </c-flex>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "DetailDailyChallengePage",
  components: { Carousel, Slide },
  mixins: [generalMixin],
  props: ["item", "images", "videos", "files", "youtube"],
  data() {
    return {
      downloading: false,
      position: 0,
      containerHeight: 0,
      windowInnerWidth: null,
      youtubeWidth: 0,
    };
  },
  mounted() {
    this.windowInnerWidth = window.innerWidth;
    this.youtubeWidth = this.windowInnerWidth - 40;
    this._scrollListener();
    window.addEventListener("scroll", this._scrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this._scrollListener);
  },
  computed: {
    ...mapGetters({
      axios: "axios",
    }),
    id() {
      return this.item?.id;
    },
  },
  methods: {
    _scrollListener() {
      this.position = Math.round(window.pageYOffset);
      const container = document.querySelector(".end-content");
      if (container) {
        this.containerHeight = container.offsetHeight;
      }
      if (
        this.id &&
        !this.item.isRead &&
        this.position > this.containerHeight
      ) {
        this.axios
          .put(`v1/clients/content-and-challenge/${this.id}/read`)
          .then((r) => r.data.data)
          .then(() => {
            this.item.isRead = true;
          });
      }

      const videos = document.getElementsByTagName("video");
      if (videos.length > 0) {
        let fraction = 0.8; // Play when 80% of the player is visible.

        for (let i = 0; i < videos.length; i++) {
          let video = videos[i];

          let x = video.offsetLeft,
            y = video.offsetTop,
            w = video.offsetWidth,
            h = video.offsetHeight,
            r = x + w, //right
            b = y + h, //bottom
            visibleX,
            visibleY,
            visible;

          visibleX = Math.max(
            0,
            Math.min(
              w,
              window.pageXOffset + window.innerWidth - x,
              r - window.pageXOffset
            )
          );
          visibleY = Math.max(
            0,
            Math.min(
              h,
              window.pageYOffset + window.innerHeight - y,
              b - window.pageYOffset
            )
          );

          visible = (visibleX * visibleY) / (w * h);

          // if (visible > fraction) {
          //   video.play();
          // }
          if (visible < fraction) {
            video.pause();
          }
        }
      }
    },
    downloadFile(idx, item) {
      this.downloading = idx;
      fetch(item.url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.fileName;
          link.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
          this.$toast({
            title: "Failed",
            description: "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
  },
};
</script>

<style>
.VueCarousel-navigation-button:before {
  content: " ";
  display: block;
  height: 45px;
  width: 45px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.VueCarousel-navigation-prev:before {
  background: url("../../../assets/icon-chevron-left-filled.svg") no-repeat
    center center;
}

.VueCarousel-navigation-next:before {
  background: url("../../../assets/icon-chevron-right-filled.svg") no-repeat
    center center;
}
</style>
