var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-flex',{attrs:{"flex-dir":"column","align-items":"center"}},[_c('Header',{attrs:{"logo":"hide","back":true,"back-title":"Detail Konten"}}),_c('c-box',{attrs:{"max-w":['inherit', '1200px'],"w":"100%","mx":"auto","mt":['30px', '60px'],"mb":"130px","px":['20px', null]}},[_c('c-flex',[_c('c-button',{attrs:{"d":['none', 'inherit'],"left-icon":"chevron-left","variant-color":"brand","variant":"link","font-weight":"normal","font-size":"14px"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Back ")]),_c('c-box',{attrs:{"as":"span","mx":"16px"}},[_vm._v("|")]),_c('Breadcrumb',{attrs:{"items":[
          { label: 'Beranda', href: '/' },
          {
            label: 'Diet and Coaching Challenge',
            href: '#/content-challenge',
          },
          { label: _vm.item.title, isCurrent: true } ]}})],1),_c('c-flex',{attrs:{"justify-content":"center","align-items":"center","borderTop":[0, '1px solid #F2F2F2'],"mt":[null, '30px']}},[_c('c-flex',{attrs:{"mt":[null, '30px'],"ml":!_vm.isNutri ? [null, '52px'] : '',"w":!_vm.isNutri ? ['100%', '850px'] : '',"flexGrow":_vm.isNutri ? 1 : '',"flex-dir":"column","align-items":"center"}},[_c('c-box',{attrs:{"w":"100%","align":"center"}},[(_vm.isNutri)?_c('c-text',{attrs:{"display":"flex","justify-content":"flex-end","align-items":"center","mt":"7px","mb":"41px"}},[_vm._v(" Bagikan : "),_c('a',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                'margin-left': '10px',
              }),expression:"{\n                'margin-left': '10px',\n              }"}],attrs:{"href":_vm.shareWhatsApp,"data-action":"share/whatsapp/share","target":"_blank"}},[_c('c-image',{attrs:{"src":require('@/assets/icon-whatsapp.svg'),"h":"32px","w":"32px","object-fit":"cover"}})],1)]):_vm._e(),_c('c-heading',{attrs:{"font-size":"36px","font-weight":"bold","lineHeight":"54px","mb":"4px"}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('c-text',{attrs:{"font-size":"18px","font-weight":"bold","lineHeight":"27px","color":"brand.900"}},[_vm._v(" "+_vm._s(_vm.category)+" ")])],1),(_vm.isCoachingCourse)?_c('DetailCoachingCourse',{attrs:{"item":_vm.item,"images":_vm.dataImages,"videos":_vm.dataVideos,"youtube":_vm.dataYoutube,"isnutri":_vm.isNutri}}):_vm._e(),(!_vm.isCoachingCourse)?_c('DetailDailyChallenge',{attrs:{"item":_vm.item,"images":_vm.dataImages,"videos":_vm.dataVideos,"files":_vm.dataFiles,"youtube":_vm.dataYoutube}}):_vm._e()],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }