<template>
  <c-flex flex-dir="column" align-items="center">
    <Header logo="hide" :back="true" back-title="Detail Konten" />

    <c-box
      :max-w="['inherit', '1200px']"
      w="100%"
      mx="auto"
      :mt="['30px', '60px']"
      mb="130px"
      :px="['20px', null]"
    >
      <c-flex>
        <c-button
          :d="['none', 'inherit']"
          left-icon="chevron-left"
          variant-color="brand"
          variant="link"
          font-weight="normal"
          font-size="14px"
          @click="$router.back()"
        >
          Back
        </c-button>
        <c-box as="span" mx="16px">|</c-box>
        <Breadcrumb
          :items="[
            { label: 'Beranda', href: '/' },
            {
              label: 'Diet and Coaching Challenge',
              href: '#/content-challenge',
            },
            { label: item.title, isCurrent: true },
          ]"
        />
      </c-flex>

      <c-flex
        justify-content="center"
        align-items="center"
        :borderTop="[0, '1px solid #F2F2F2']"
        :mt="[null, '30px']"
      >
        <c-flex
          :mt="[null, '30px']"
          :ml="!isNutri ? [null, '52px'] : ''"
          :w="!isNutri ? ['100%', '850px'] : ''"
          :flexGrow="isNutri ? 1 : ''"
          flex-dir="column"
          align-items="center"
        >
          <c-box w="100%" align="center">
            <c-text
              v-if="isNutri"
              display="flex"
              justify-content="flex-end"
              align-items="center"
              mt="7px"
              mb="41px"
            >
              Bagikan :
              <a
                :href="shareWhatsApp"
                data-action="share/whatsapp/share"
                target="_blank"
                v-chakra="{
                  'margin-left': '10px',
                }"
              >
                <c-image
                  :src="require('@/assets/icon-whatsapp.svg')"
                  h="32px"
                  w="32px"
                  object-fit="cover"
                />
              </a>
            </c-text>
            <c-heading
              font-size="36px"
              font-weight="bold"
              lineHeight="54px"
              mb="4px"
            >
              {{ item.title }}
            </c-heading>
            <c-text
              font-size="18px"
              font-weight="bold"
              lineHeight="27px"
              color="brand.900"
            >
              {{ category }}
            </c-text>
          </c-box>

          <DetailCoachingCourse
            v-if="isCoachingCourse"
            :item="item"
            :images="dataImages"
            :videos="dataVideos"
            :youtube="dataYoutube"
            :isnutri="isNutri"
          />
          <DetailDailyChallenge
            v-if="!isCoachingCourse"
            :item="item"
            :images="dataImages"
            :videos="dataVideos"
            :files="dataFiles"
            :youtube="dataYoutube"
          />
        </c-flex>
      </c-flex>
    </c-box>
    <Footer />
  </c-flex>
</template>

<script>
import Header from "@/components/header";
import Breadcrumb from "@/components/breadcrumb";
import Footer from "@/components/Footer.vue";
import DetailCoachingCourse from "@/views/content-challenge/detail/course.vue";
import DetailDailyChallenge from "@/views/content-challenge/detail/daily-challenge.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "DetailContentChallengePage",
  components: {
    Header,
    Breadcrumb,
    Footer,
    DetailCoachingCourse,
    DetailDailyChallenge,
  },
  data() {
    return {
      currentUrl: null,
    };
  },
  metaInfo() {
    return {
      title: this.item.title,
      meta: [
        {
          name: "description",
          content: this.item.description,
        },
        {
          property: "og:title",
          content: this.item.title,
        },
        {
          property: "og:url",
          content: this.$route.fullPath,
        },
        {
          property: "og:description",
          content: this.item.description,
        },
        {
          property: "og:image",
          content:
            "https://storage.skyshi.io/dietela/content-and-challenge/5b16a244-c917-4241-b5f4-9721da7959ef/2021-11/seimbangkan-porsi-makan.jpg",
        },
        { property: "og:type", content: "article" },
        { property: "og:site_name", content: "Dietela" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    ...mapGetters({
      axios: "axios",
      category: "contentChallenge/labelCategory",
      item: "contentChallenge/item",
    }),
    isNutri() {
      if (this.user && this.user.role == "nutritionist") return true;
      return false;
    },
    isClient() {
      if (this.user && this.user.role == "client") return true;
      return false;
    },
    isCoachingCourse() {
      if (this.item.type === "coaching_course") return true;
      return false;
    },
    dataImages() {
      if (this.item && this.item.media) {
        return this.item.media.filter(
          (dt) => dt.type === "image" && !dt.preview
        );
      }
      return [];
    },
    dataVideos() {
      if (this.item && this.item.media) {
        return this.item.media.filter((dt) => dt.type === "video");
      }
      return [];
    },
    dataFiles() {
      if (this.item && this.item.media) {
        return this.item.media.filter((dt) => dt.type === "file");
      }
      return [];
    },
    dataYoutube() {
      if (this.item && this.item.media) {
        return this.item.media.filter((dt) => dt.type === "youtube");
      }
      return [];
    },
    shareWhatsApp() {
      return `whatsapp://send?text=Baca lebih lanjut tentang ini yuk:%0a*${this.item.title}*%0a${this.category}%0a%0a${this.currentUrl}`;
    },
  },
  methods: {
    ...mapActions({
      getCategory: "contentChallenge/getLabelCategory",
      detailContentChallengeClient:
        "contentChallenge/detailContentChallengeClient",
      detailContentChallengeNutritionist:
        "contentChallenge/detailContentChallengeNutritionist",
    }),
  },
  created: function () {
    this.currentUrl = window.location.href;
  },
  async mounted() {
    if (!this.user) {
      let hashes = location.hash.slice(1);
      if (hashes.includes('next="')) {
        hashes = hashes.replace(/next="(.*?)"/, `next=${hashes}`);
      } else {
        hashes = `next=${hashes}`;
      }
      location.replace(`#/login?${hashes}`);
    }
    let id = this.$route.params.id;
    if (this.isClient) {
      await this.detailContentChallengeClient(id).then(() => {
        this.getCategory({
          type: this.item.type,
          category: this.item.category,
        });
      });
    }
    if (this.isNutri) {
      await this.detailContentChallengeNutritionist(id).then(() => {
        this.getCategory({
          type: this.item.type,
          category: this.item.category,
        });
      });
    }
  },
};
</script>
<style>
table {
  width: 100%;
  border: thin solid #c4c4c4;
}
table tr th {
  border-left: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  padding: 4px 8px;
}
table tr td {
  border-left: thin solid #c4c4c4;
  border-bottom: thin solid #c4c4c4;
  padding: 4px 8px;
}
</style>