<template>
  <c-box :w="!isNutri ? ['100%', '794px'] : ''" mt="63px" :mb="['63px', null]">
    <c-text
      align="justify"
      fontSize="18px"
      lineHeight="27px"
      color="#555555"
      mb="63px"
      v-html="item.description"
    />
    <c-flex
      v-for="(media, idx) in images"
      :key="'image' + idx"
      flex-dir="column"
      align-items="center"
      mb="41px"
    >
      <c-box
        w="100%"
        min-h="100px"
        border-radius="10px"
        overflow="hidden"
        bg="#C4C4C4"
      >
        <c-image :src="media.url" :alt="media.caption" />
      </c-box>
      <c-text
        align="justify"
        fontSize="18px"
        lineHeight="27px"
        color="#555555"
        mt="26px"
        w="100%"
      >
        {{ media.caption }}
      </c-text>
    </c-flex>
    <c-flex
      v-for="(media, index) in videos"
      :key="index"
      flex-dir="column"
      align-items="center"
      mb="41px"
    >
      <c-box
        w="100%"
        min-h="100px"
        border-radius="10px"
        overflow="hidden"
        bg="#C4C4C4"
      >
        <video
          v-if="media.type === 'video'"
          v-chakra
          w="100%"
          h="100%"
          :src="media.url"
          controls
          autoplay="false"
          muted="muted"
        />
      </c-box>
      <c-text
        align="justify"
        fontSize="18px"
        lineHeight="27px"
        color="#555555"
        mt="26px"
        w="100%"
      >
        {{ media.caption }}
      </c-text>
    </c-flex>
    <c-flex
      v-for="(media, index) in youtube"
      :key="'yt' + index"
      align="center"
      justify="center"
      mt="52px"
    >
      <youtube :video-id="getYoutubeId(media.url)"></youtube>
    </c-flex>
  </c-box>
</template>

<script>
import generalMixin from "@/utils/general-mixins";
export default {
  name: "DetailCoachingCoursePage",
  mixins: [generalMixin],
  props: ["item", "images", "videos", "youtube", "isNutri"],
  mounted() {
    this._scrollListener();
    window.addEventListener("scroll", this._scrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this._scrollListener);
  },
  methods: {
    _scrollListener() {
      const videos = document.getElementsByTagName("video");
      if (videos.length > 0) {
        let fraction = 0.8; // Play when 80% of the player is visible.

        for (let i = 0; i < videos.length; i++) {
          let video = videos[i];

          let x = video.offsetLeft,
            y = video.offsetTop,
            w = video.offsetWidth,
            h = video.offsetHeight,
            r = x + w, //right
            b = y + h, //bottom
            visibleX,
            visibleY,
            visible;

          visibleX = Math.max(
            0,
            Math.min(
              w,
              window.pageXOffset + window.innerWidth - x,
              r - window.pageXOffset
            )
          );
          visibleY = Math.max(
            0,
            Math.min(
              h,
              window.pageYOffset + window.innerHeight - y,
              b - window.pageYOffset
            )
          );

          visible = (visibleX * visibleY) / (w * h);

          // if (visible > fraction) {
          //   video.play();
          // }
          if (visible < fraction) {
            video.pause();
          }
        }
      }
    },
  },
};
</script>